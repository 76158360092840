.projects {
  margin-top: 250px;
}

.projects h2 {
  text-align: center;
  font-size: 70px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
}

.projects h2 span {
  color: var(--lime);
}

.projects .wrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.projects .column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.projects .column.center {
  margin-top: 90px;
}

.projects .video_block {
  width: 100%;
  min-height: 260px;
  border-radius: 16px;
  box-shadow: 0px 0px 50px 21px #acf21536;
  transition: all 0.3s ease;
}

.projects .video {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
}

.projects .video_block:hover {
  border-radius: 16px;
  transform: translateY(-15px);
}

.projects .video_block:active {
  transform: translateY(1px);
}

.projects .img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 16px;
}

@media (max-width: 760px) {
  .projects {
    margin-top: 200px;
  }

  .projects h2 {
    font-size: 30px;
  }

  .projects .wrapper {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .projects .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .projects .column.center {
    margin-top: 0;
  }

  .projects .video_block {
    width: 100%;
    min-height: 290px;
    border-radius: 16px;
    box-shadow: 0px 0px 50px 21px #acf21536;
    transition: all 0.3s ease;
  }

  .projects .video {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: top;
  }

  .projects .video_block:hover {
    transform: translateY(-15px);
  }

  .projects .video_block:active {
    transform: translateY(1px);
  }
}

.dowload.app {
  display: none !important;
}

@supports (-webkit-touch-callout: none) {
  .dowload.play {
    display: none !important;
  }

  .dowload.app {
    display: block !important;
  }
}
