.first_block {
  width: 100%;
}

.first_block .blockx {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.first_block h1 {
  margin-top: 55px;
  font-size: 100px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
  z-index: 1;
}

.first_block h1 span {
  color: var(--lime);
}

.first_block .btn {
  max-width: 215px;
  margin-top: 50px;
  cursor: pointer;
  border-radius: 50px;
  padding: 0 20px;
  border: 1px solid var(--lime);
  background: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.first_block .btn:hover {
  background: rgba(255, 255, 255, 0.154);
}

.first_block .btn p {
  font-size: 16px;
  font-weight: 400;
  color: var(--lime);
  font-family: Roboto;
}

.first_block .btn .arrow {
  position: static;
  width: 24px;
  height: 24px;
}

.first_block .text {
  width: 360px;
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  font-family: Roboto;
}

.first_block .absolute1 {
  margin-top: 24px;
}

.first_block .absolute2 {
  position: absolute;
  top: 30px;
  left: 680px;
}

.first_block .image_box {
  position: absolute;
  top: 150px;
  left: 500px;
  width: 720px;
  height: 670px;
}

.first_block .image_absolute3 {
  position: absolute;
  top: 230px;
  right: 0;
  width: 560px;
  height: 560px;
  animation: swing1 5s linear infinite;
}

.first_block .image_absolute1 {
  position: absolute;
  top: 30px;
  right: -5%;
  width: 190px;
  height: 190px;
  animation: float 3s linear infinite;
}

.first_block .image_absolute2 {
  position: absolute;
  top: 481px;
  right: 560px;
  width: 270px;
  height: 270px;
  transform-origin: top left;
  animation: swing2 2s linear infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(3rem);
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(7deg);
  }
  40% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-7deg);
  }
  80% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes swing1 {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(-7deg);
  }
  90% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.first_block .green_btn_a {
  position: relative;
  z-index: 99;
  text-decoration: none;
}

.first_block .block_btn {
  display: none;
}

@media (max-width: 760px) {
  .first_block .block_btn {
    display: block;
  }

  .first_block h1 {
    top: 45px;
    font-size: 40px;
  }

  .first_block .btn {
    margin-top: 30px;
  }

  .first_block .text {
    max-width: 450px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #979797;
    font-family: Roboto;
  }

  .first_block .absolute2 {
    display: none;
  }

  .first_block .image_box {
    position: absolute;
    top: 300px;
    left: 0;
    right: 0;
    width: 100%;
    height: 530px;
  }

  .first_block .image_absolute3 {
    position: absolute;
    top: 400px;
    right: 5px;
    width: 330px;
    height: 330px;
    animation: swing1 5s linear infinite;
  }

  .first_block .image_absolute1 {
    position: absolute;
    top: 310px;
    right: -16px;
    width: 100px;
    height: 100px;
    animation: float 3s linear infinite;
  }

  .first_block .image_absolute2 {
    position: absolute;
    top: 651px;
    left: -40px;
    width: 100px;
    height: 100px;
    transform-origin: top left;
    animation: swing2 2s linear infinite;
  }
}
