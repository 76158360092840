.advantages {
  width: 100%;
}

.advantages .center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advantages .image_ad {
  width: 580px;
  height: 280px;
}

.advantages .line {
  margin-top: 50px;
  position: relative;
  height: 84px;
}

.advantages .line h2 {
  text-align: center;
  font-size: 100px;
  overflow: hidden;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
  opacity: 0;
  height: 84px;
  transform: translateY(100%);
}

.advantages .line h2.animate {
  animation: openPages 0.6s ease forwards;
}

@keyframes openPages {
  0% {
    opacity: 0;
    height: 0;
    transform: translateY(100%);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    height: 84px;
    transform: translateY(0);
  }
}

.advantages .line h2 span {
  color: var(--lime);
}

.advantages .lines {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: var(--white);
  border-radius: 50px;
}

.advantages .wrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.advantages .box {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #2c2c2c;
  transition: all 0.3s ease;
}

.advantages .box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(172, 242, 21, 0.518);
}

.advantages .box img {
  width: 140px;
  height: 140px;
}

.advantages .box .title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  color: var(--white);
}

.advantages .box .text {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #c7c6c6;
  font-family: Roboto;
}

.advantages .box.green {
  background: var(--lime);
}

.advantages .title.green {
  color: var(--black);
}

.advantages .text.green {
  color: #444444;
}

.advantages .block {
  display: none;
}

@media (max-width: 760px) {
  .advantages .none {
    display: none;
  }

  .advantages .block {
    display: block;
  }

  .advantages {
    margin-top: 150px;
    width: 100%;
  }

  .advantages .center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .advantages .image_ad {
    width: 100%;
    height: 100%;
  }

  .advantages .line {
    margin-top: 25px;
    position: relative;
    height: 40px;
  }

  .advantages .line h2 {
    text-align: center;
    font-size: 27px;
    overflow: hidden;
    font-weight: 400;
    color: var(--white);
    text-transform: uppercase;
    opacity: 0;
    height: 40px;
    transform: translateY(100%);
  }

  .advantages .line h2.animate {
    animation: openPages 0.6s ease forwards;
  }

  @keyframes openPages {
    0% {
      opacity: 0;
      height: 0;
      transform: translateY(100%);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      height: 84px;
      transform: translateY(0);
    }
  }

  .advantages .line h2 span {
    color: var(--lime);
  }

  .advantages .lines {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: var(--white);
    border-radius: 50px;
  }

  .advantages .wrapper {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .advantages .box {
    width: 100%;
    padding: 16px;
    border-radius: 20px;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #2c2c2c;
    transition: all 0.3s ease;
  }

  .advantages .box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(172, 242, 21, 0.518);
  }

  .advantages .box img {
    width: 140px;
    height: 140px;
  }

  .advantages .box .title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    color: var(--white);
  }

  .advantages .box .text {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #c7c6c6;
    font-family: Roboto;
  }

  .advantages .box.green {
    background: var(--lime);
  }

  .advantages .title.green {
    color: var(--black);
  }

  .advantages .text.green {
    color: #444444;
  }
}
