.header {
  width: 100%;
  height: 80px;
}

.header h2 {
  font-size: 24px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
}

.header h2 span {
  color: var(--lime);
}

.header .head_absolute {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #444444;
  backdrop-filter: blur(4px);
  background: rgba(10, 10, 11, 0.2);
  z-index: 10;
}

.header .between {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .flex {
  display: flex;
  align-items: center;
  gap: 40px;
}

.header a,
.header .page {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  font-family: Roboto;
  text-decoration: none;
  transition: 0.6s;
  position: relative;
  transition: 0.2s ease;
}

.header a:hover,
.header .page:hover {
  color: var(--lime);
}

.header .page:hover::before {
  transform: scaleX(1);
  transform-origin: center;
  transition: transform 0.3s;
}

.header .page::before {
  content: "";
  width: 100%;
  height: 1px;
  border-radius: 50px;
  background: var(--lime);
  position: absolute;
  left: 0;
  bottom: -3px;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s;
}

.header .btn {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 50px;
  border-radius: 50px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.header .btn .btn_text {
  color: var(--black);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.leng {
  position: relative;
}

.default {
  height: 30px;
  box-shadow: 0px 0px 12px var(--white);
  border-radius: 6px;
  transition: 0.3s ease;
}

.flag {
  cursor: pointer;
  width: 50px;
  height: 30px;
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
  transition: 0.3s ease;
}

.default:hover {
  box-shadow: 0px 0px 35px var(--white);
}

.menu_lang {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  transition: 0.3s ease;
  animation: langing 0.3s ease;
}

.menu_lang:hover {
  left: -3px;
}

.menu_lang:hover .default,
.menu_lang:hover .flag {
  width: 56px;
  height: 36px;
}

@keyframes langing {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 40px;
    opacity: 1;
  }
}

@media (max-width: 760px) {
  .header {
    height: 70px;
  }
  .header .flex {
    display: none;
  }
  .header h2 {
    font-size: 20px;
  }
}
