.con {
  margin: 0;
  overflow-x: hidden;
  height: 100%;
  padding: 240px 0;
}

.con .from_block {
  padding: 35px 30px;
  background: var(--lime);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}

.con .from_block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(
    circle at var(--x) var(--y),
    transparent,
    rgba(0, 0, 0, 0.4)
  );
  transition: background 0.1s;
}

.con .arrow {
  position: absolute;
  top: 50%;
  left: 40%;
}

.con .from_block h3 {
  font-size: 70px;
  font-weight: 400;
  color: var(--black);
  text-transform: uppercase;
}

.con .from_block .forming {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.con .from_block .forming label {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  font-family: Roboto;
}

.con .from_block .forming input {
  margin-top: 8px;
  width: 100%;
  height: 50px;
  outline: none;
  border-radius: 50px;
  padding-left: 20px;
  border: 1px solid var(--black);
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto;
}

.con .error::placeholder {
  color: red !important;
}

.con .from_block .forming input::placeholder {
  color: #787878;
}

.con .from_block .forming input:focus {
  border: 1px solid var(--white);
}

.con .btn {
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  background: var(--white);
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  font-family: Roboto;
  border: none;
  transition: all 0.3s ease;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con .one {
  position: fixed;
  top: 350px;
  left: 50px;
}

.con .two {
  position: fixed;
  top: 350px;
  right: 50px;
}

.con .three {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
}

@media (max-width: 760px) {
  .con .none {
    display: none;
  }
  .con {
    margin: 0;
    overflow-x: hidden;
    height: 100%;
    padding: 200px 0;
  }

  .con .from_block {
    padding: 16px;
    padding-top: 30px;
    background: var(--lime);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
  }

  .con .from_block::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(
      circle at var(--x) var(--y),
      transparent,
      rgba(0, 0, 0, 0.2)
    );
    transition: background 0.1s;
  }

  .con .arrow {
    position: absolute;
    top: 170px;
    left: 16px;
    width: 190px;
  }

  .con .from_block h3 {
    width: 100%;
    font-size: 30px;
    font-weight: 400;
    color: var(--black);
    text-transform: uppercase;
  }

  .con .from_block .forming {
    margin-top: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .con .from_block .forming label {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    font-family: Roboto;
  }

  .con .from_block .forming input {
    margin-top: 8px;
    width: 100%;
    height: 50px;
    outline: none;
    border-radius: 50px;
    padding-left: 20px;
    border: 1px solid var(--black);
    background: transparent;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
  }

  .con .error::placeholder {
    color: red !important;
  }

  .con .from_block .forming input::placeholder {
    color: #787878;
  }

  .con .from_block .forming input:focus {
    border: 1px solid var(--white);
  }

  .con .btn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background: var(--white);
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    font-family: Roboto;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .con .one {
    position: fixed;
    top: 350px;
    left: 50px;
  }

  .con .two {
    position: fixed;
    top: 350px;
    right: 50px;
  }

  .con .three {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%);
  }
}
