.footer {
  width: 100%;
  min-height: 250px;
  background: #2c2c2c;
  padding-top: 75px;
  padding-bottom: 20px;
}

.footer .grid {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
}

.footer .trr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.footer h3 {
  font-size: 45px;
  font-weight: 400;
  color: var(--white);
}

.footer h3 span {
  color: var(--lime);
}

.footer .text {
  max-width: 300px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
  font-family: Roboto;
}

.footer .column {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.footer .we,
.footer .column a {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  text-decoration: none;
  position: relative;
  transition: 0.2s ease;
}

.footer a:hover {
  color: var(--lime);
}

.footer .gred {
  margin-top: 26px;
  display: flex;
  gap: 20px;
}

.footer .page {
  cursor: pointer;
}

.footer .page:hover::before {
  transform: scaleX(1);
  transform-origin: center;
  transition: transform 0.3s;
}

.footer .page::before {
  content: "";
  width: 100%;
  height: 1px;
  border-radius: 50px;
  background: var(--lime);
  position: absolute;
  left: 0;
  bottom: -3px;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s;
}

.footer .link-container {
  position: relative;
  display: inline-block;
}

.footer .links {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform: translateX(-50%);
  background-color: var(--lime);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
  border-radius: 4px;
}

.footer .link-container:hover .links {
  display: block;
}

.footer .links a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.footer .links a:hover {
  background-color: var(--white);
}

.footer .block {
  display: none;
}

@media (max-width: 760px) {
  .footer .we,
  .footer .column a {
    font-size: 14px;
  }
  .footer {
    padding-top: 20px;
  }
  .footer .grid {
    grid-template-columns: 1fr;
  }
  .footer .trr {
    margin-top: 60px;
  }
  .footer .none {
    display: none;
  }
  .footer .top {
    margin-top: 60px;
  }
  .footer .block {
    display: block;
    margin-top: 40px;
  }
  .footer .gred {
    margin-top: 26px;
  }
  .footer .gred img {
    width: 60px;
    height: 60px;
  }
}
