@import "aos/dist/aos.css";

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Unbounded;
  src: url("../src/assets/fonts/Unbounded-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Roboto;
  src: url("../src/assets/fonts/Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("../src/assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("../src/assets/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("../src/assets/fonts/Roboto-Bold.ttf");
  font-weight: 600;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Unbounded, sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.App {
  width: 100%;
  min-height: 100vh;
  background: #000;
}

:root {
  --black: #000;
  --white: #fff;
  --lime: #acf215;
  --gray: #979797;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn:active {
  transform: translateY(1px);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #acf215c4;
  border-radius: 6px;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #acf215db;
}

::-webkit-scrollbar-thumb:active {
  background-color: #acf215;
}

