.servicies {
  margin-top: 250px;
  width: 100%;
}

.servicies h2 {
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 400;
  color: var(--white);
}

.servicies h2 span {
  color: var(--lime);
}

.servicies .wrapper {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
}

.servicies .save {
  padding-top: 20px;
  width: 100%;
  position: relative;
  height: 130px;
  transition: 0.6s ease;
  border-bottom: 1px solid #444444;
}

.servicies .head_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.servicies .title {
  max-width: 60%;
  font-size: 36px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
}

.servicies .flex {
  display: flex;
  align-items: center;
  gap: 30px;
}

.servicies .name {
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  font-family: Roboto;
}

.servicies .arrows {
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #444444;
  position: relative;
  transition: 0.4s ease;
}

.servicies .arrows::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 48px;
  height: 48px;
  background-image: url("../../assets/images/arrow_down.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease;
}

.servicies .box {
  display: none;
  transition: 0.5s ease;
  animation: openPage 0.6s ease;
}

.servicies .text {
  max-width: 500px;
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  transition: 0.5s ease;
  font-family: Roboto;
  animation: openPage 0.6s ease;
}

.servicies .box img {
  position: absolute;
  top: 20px;
  bottom: 20px;
  right: 160px;
  width: 300px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  animation: openPage 0.6s ease;
}

@keyframes openPage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.servicies .save:hover {
  height: 290px;
  border-bottom: 1px solid var(--lime);
}

.servicies .save:hover .name {
  display: none;
}

.servicies .save:hover .box {
  display: block;
}

.servicies .save:hover .arrows {
  border: 1px solid var(--lime);
}

.servicies .save:hover .arrows::before {
  transform: rotate(-90deg);
}

.servicies .saveblock.active {
  border-bottom: 1px solid var(--lime);
}

.servicies .saveblock.active .arrowsblock {
  border: 1px solid var(--lime);
}

.servicies .saveblock.active .arrowsblock::before {
  transform: rotate(-90deg);
}

.servicies .block {
  display: none;
}

@media (max-width: 760px) {
  .servicies .none {
    display: none !important;
  }

  .servicies .block {
    display: block;
  }

  .servicies {
    margin-top: 100px;
  }

  .servicies h2 {
    font-size: 30px;
  }

  .servicies .wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .servicies .saveblock {
    padding-top: 20px;
    width: 100%;
    position: relative;
    min-height: 150px;
    transition: 0.6s ease;
    border-bottom: 1px solid #444444;
  }

  .servicies .head_boxblock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .servicies .titleblock {
    font-size: 20px;
    font-weight: 400;
    color: var(--white);
    text-transform: uppercase;
  }

  .servicies .flexblock {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .servicies .nameblock {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #979797;
    font-family: Roboto;
  }

  .servicies .arrowsblock {
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #444444;
    position: relative;
    transition: 0.4s ease;
  }

  .servicies .arrowsblock::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 48px;
    height: 48px;
    background-image: url("../../assets/images/arrow_down.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s ease;
  }

  .servicies .boxblock {
    transition: 0.5s ease;
    animation: openPage 0.6s ease;
  }

  .servicies .textblock {
    margin-top: 10px;
    max-width: 1000px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #979797;
    transition: 0.5s ease;
    font-family: Roboto;
    animation: openPage 0.6s ease;
  }

  .servicies .boxblock img {
    margin: 20px 0;
    position: static;
    bottom: 20px;
    right: 160px;
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
    animation: openPage 0.6s ease;
    animation: float 3s linear infinite;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(1rem);
  }
}
