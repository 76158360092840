.programs {
  margin-top: 250px;
  width: 100%;
}

.programs h2 {
  font-size: 70px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
}

.programs h2 span {
  color: var(--lime);
}

.programs .wrapper {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.programs .program_block {
  height: 120px;
  border-radius: 100px;
  border: 1px solid #979797;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.programs .program_block {
  cursor: grab;
  user-select: none;
}

.programs .program_block :active {
  cursor: grabbing;
}

.programs .program_block.program {
  background: #2c2c2c;
  border: 1px solid #979797;
}

.programs .program_block.program p {
  color: var(--lime);
}

.programs .program_block.main {
  border: 1px solid var(--lime);
  background: var(--lime);
}

.programs .program_block p {
  font-size: 40px;
  font-weight: 400;
  color: var(--white);
  white-space: nowrap;
  font-family: Roboto;
}

.programs .program_block.main p {
  color: var(--black);
}

.programs .program_block .image {
  width: 80px;
  height: 80px;
}

.programs .program_block .image.main {
  background: var(--white);
  border-radius: 10px;
}

@media (max-width: 760px) {
  .programs {
    margin-top: 200px;
    width: 100%;
  }

  .programs h2 {
    font-size: 30px;
  }

  .programs .wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .programs .program_block {
    height: 60px;
    border-radius: 100px;
    border: 1px solid #979797;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .programs .program_block {
    cursor: grab;
    user-select: none;
  }

  .programs .program_block :active {
    cursor: grabbing;
  }

  .programs .program_block.program {
    background: #2c2c2c;
    border: 1px solid #979797;
  }

  .programs .program_block.program p {
    color: var(--lime);
  }

  .programs .program_block.main {
    border: 1px solid var(--lime);
    background: var(--lime);
  }

  .programs .program_block p {
    font-size: 20px;
    font-weight: 400;
    color: var(--white);
    white-space: nowrap;
    font-family: Roboto;
  }

  .programs .program_block.main p {
    color: var(--black);
  }

  .programs .program_block .image {
    width: 40px;
    height: 40px;
  }

  .programs .program_block .image.main {
    background: var(--white);
    border-radius: 10px;
  }

  .programs .flex {
    height: 60px;
  }
}
