.tariffs {
  padding: 250px 0;
  width: 100%;
  background-image: url("../../assets/images/bg_green.png");
  background-color: #00000020;
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s ease;
}

.tariffs .tabs {
  margin-top: 60px;
  width: 100%;
  border-bottom: 1px solid var(--white);
}

.tariffs *::-webkit-scrollbar {
  display: none;
}

.tariffs .btns_tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 10px;
}

.tariffs .btn_tab {
  cursor: pointer;
  padding: 0 30px;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.3s ease;
}

.tariffs .btn_tab.active {
  color: var(--black);
  background: var(--white);
  border-bottom: none;
}

.tariffs h2 {
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 400;
  color: var(--white);
}

.tariffs h2 span {
  color: var(--lime);
}

.tariffs .scroll_wrapper {
  margin-top: 30px;
  width: 100%;
}

.tariffs .wrapper {
  column-count: 3;
  column-gap: 30px;
}

.tariffs .tariffs_box {
  margin-bottom: 30px;
  break-inside: avoid;
  position: relative;
  cursor: pointer;
  border-radius: 14px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s ease;
}

.tariffs .tariffs_box.active {
  background: var(--black);
  border: 1px solid rgba(255, 217, 0, 0.506);
}

.tariffs .tariffs_box:hover {
  transform: translateY(-10px);
}

.tariffs .tariffs_box:active {
  transform: scale(0.95);
}

.tariffs .tariffs_box .con_tariffs_box {
  padding: 0 16px;
}

.tariffs .tariffs_box .points {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tariffs .tariffs_box .title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
}

.tariffs .tariffs_box .desc {
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 300;
  color: var(--gray);
  font-family: Roboto;
}

.tariffs .point_box {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #cdcdcd56;
  padding: 0 16px;
  padding-bottom: 10px;
}

.tariffs .point_box.border {
  border: none;
}

.tariffs .tariffs_box .point {
  font-size: 16px;
  font-weight: 300;
  color: var(--white);
  font-family: Roboto;
}

.tariffs .point_sign {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--gray);
}

.tariffs .blocks {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tariffs .timeline {
  font-size: 15px;
  font-weight: 300;
  color: var(--gray);
  font-family: Roboto;
}

.tariffs .price {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  font-family: Roboto;
}

.tariffs .btngo {
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  height: 50px;
  background: var(--white);
  color: var(--black);
  font-weight: 16;
  font-weight: 500;
}

@media (max-width: 760px) {
  .tariffs {
    padding-top: 200px;
    padding-bottom: 50px;
  }

  .tariffs h2 {
    font-size: 30px;
  }

  .tariffs .wrapper {
    column-count: 1;
  }

  .tariffs .btns_tabs {
    width: 100%;
    flex-wrap: nowrap;
  }

  .tariffs .btn_tab {
    flex: 0 0 auto;
  }

  .tariffs .btn_tab.first {
    margin-left: 5%;
  }

  .tariffs .btn_tab.last {
    margin-right: 5%;
  }

  .tariffs .tabo.container {
    width: 100% !important;
  }
}
