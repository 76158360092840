.stages {
  margin-top: 270px;
  width: 100%;
}

.stages h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 400;
  color: var(--white);
}

.stages h2 span {
  color: var(--lime);
}

.stages .grid {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.stages .box {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  padding-top: 44px;
  background: #2c2c2c;
  position: relative;
  transition: all 0.3s ease;
}

.stages .box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(172, 242, 21, 0.518);
}

.stages .title {
  font-size: 24px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
}

.stages .text {
  max-width: 250px;
  margin-top: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #c7c6c6;
  font-family: Roboto;
}

.stages .right {
  margin-left: auto;
  text-align: end;
}

.stages .box img {
  position: absolute;
  bottom: 1px;
}

.stages .left {
  right: 20px;
}

.stages .block {
  display: none;
}

@media (max-width: 760px) {
  .stages .none {
    display: none !important;
  }

  .stages .block {
    display: block;
  }

  .stages {
    margin-top: 200px;
    width: 100%;
  }

  .stages h2 {
    font-size: 30px;
  }

  .stages .grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .stages .box {
    width: 100%;
    min-height: 140px;
    padding: 10px;
    border-radius: 20px;
    padding-top: 20px;
    background: #2c2c2c;
    position: relative;
    transition: all 0.3s ease;
  }

  .stages .box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(172, 242, 21, 0.518);
  }

  .stages .title {
    font-size: 16px;
    font-weight: 400;
    color: var(--white);
    text-transform: uppercase;
  }

  .stages .text {
    max-width: 160px;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #c7c6c6;
    font-family: Roboto;
  }

  .stages .right {
    margin-left: auto;
    text-align: end;
  }

  .stages .box img {
    position: absolute;
    bottom: 1px;
    width: 130px;
  }

  .stages .left {
    right: 20px;
  }
}
